export const TUTORIAL = {
  COOKIE: 'showTutorial',
  STATES: {
    CHECK: 'CHECK',
    SHOW: 'SHOW',
    CLOSED: 'CLOSED'
  }
};

export const NEW_PRINTER_ADDED = {
  COOKIE: 'newPrinterAdded',
  STATES: {
    CHECK: 'CHECK',
    SHOW: 'SHOW',
    CLOSED: 'CLOSED'
  }
};

export const EMAIL_NOTIFICATION = {
  STATES: {
    CHECK: 'CHECK',
    ON_BACKGROUND: 'ON_BACKGROUND',
    CLOSED: 'CLOSED',
    SHOW: 'SHOW'
  }
};

export const DASHBOARD_PATH = '/';
export const PRINTERS_PATH = '/printers';
export const SOLUTIONS_PATH = '/solutions';
export const USERS_PATH = '/users';
export const HELP_CENTER_PATH = '/helpcenter';
export const SETTINGS_PATH = '/settings';
