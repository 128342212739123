import { SystemConfigurationResponse } from 'src/schemas/systemConfiguration';
import { SingleDeviceCacheResponse } from '../schemas/deviceCache';
import NoImageAvailable from '../assets/images/no-image-available.svg';

export const isEqualCaseInsensiteve = (text1: string, text2: string) =>
  text1.toLowerCase() === text2.toLocaleLowerCase();

export const isPrinterHpPlus = (printer: SingleDeviceCacheResponse) => {
  const programLevel = printer?.identity?.programLevel;
  return !!programLevel && isEqualCaseInsensiteve(programLevel, 'HP+');
};

export const getPrinterImage = (printer: SingleDeviceCacheResponse) => {
  const [imgObj1, imgObj2, imgObj3] = printer?.images || [];
  const imgObj = imgObj3 || imgObj2 || imgObj1;
  return imgObj?.url || NoImageAvailable;
};

const getFieldFromSystemConfigData = (
  data: SystemConfigurationResponse,
  field: string
) =>
  data?.state?.desired?.cdmData?.[field] ||
  data?.state?.reported?.cdmData?.[field] ||
  '';

export const getPrinterFriendlyName = (response: SystemConfigurationResponse) =>
  getFieldFromSystemConfigData(response, 'deviceDescription');

export const getPrinterLocation = (response: SystemConfigurationResponse) =>
  getFieldFromSystemConfigData(response, 'deviceLocation');
