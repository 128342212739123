import styled from 'styled-components';
import { mixins } from '@jarvis/react-portal-addons';
import primitives from '@veneer/primitives';
import { Modal as VeneerModal } from '@veneer/core';

const TRANSITION_DURATION = '0.2s';

type NewStepProps = {
  newSteps?: boolean;
};

type StepProps = {
  index: number;
  show: boolean;
};

type ModalButtonsProps = {
  flexDirection?: string;
  newSteps: boolean;
};

export const Content = styled.div`
  padding: 24px 24px 0;

  ${mixins.md(`
    padding: 40px 48px 0;
  `)}
`;

export const FadeInOut = styled.div<NewStepProps>`
  display: grid;
  grid-column-gap: 0;
  grid-row-gap: 0;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: ${(props) => (props.newSteps ? 'center' : undefined)};

  > div > img {
    transform: ${(props) => (props.newSteps ? 'scale(0.9)' : undefined)};
  }

  > * {
    grid-area: 1 / 1 / 2 / 2;
    transition: opacity ${TRANSITION_DURATION} ease-out;

    &.show {
      opacity: 1;
    }

    &.hide {
      opacity: 0;
    }
  }
`;

export const Header = styled.div<{ backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  border-top-left-radius: ${primitives.layout.cornerRadius4}px;
  border-top-right-radius: ${primitives.layout.cornerRadius4}px;
  box-sizing: border-box;
  display: none;
  height: 256px;
  padding: 40px 40px 24px;

  ${mixins.md(`
    display: block;
  `)}
`;

export const HeaderContent = styled.div<NewStepProps>`
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: space-between;
  margin-top: ${(props) => (props.newSteps ? '-40px' : undefined)};
`;

export const HeaderText = styled.div<NewStepProps>`
  display: flex;
  margin-top: ${(props) => (props.newSteps ? '-90px' : undefined)};
  // This positioning solves an issue when scaling the header images they end up
  // covering the text. If they aren't scaled, the text is shown correctly
  position: ${(props) => (props.newSteps ? 'relative' : undefined)};

  > div {
    flex: 1 1 0;
    text-align: center;
  }
`;

export const Indicator = styled.div<NewStepProps>`
  display: flex;
  flex: 0 0 8px;
  gap: 16px;
  justify-content: center;
  margin: 0 auto;
  width: ${(props) => (props.newSteps ? '95px' : '72px')};
  position: ${(props) => (props.newSteps ? 'absolute' : undefined)};
  top: ${(props) => (props.newSteps ? '228px' : undefined)};
  left: ${(props) => (props.newSteps ? '274px' : undefined)};

  > div {
    background-color: #adadad;
    border-radius: ${primitives.layout.cornerRadius1}px;
    height: 8px;
    transition: width ${TRANSITION_DURATION} linear;
    width: 8px;

    &.current {
      background-color: #027aae;
      width: 20px;
    }
  }
`;

export const MenuHighlightContent = styled.div<NewStepProps>`
  box-sizing: border-box;
  display: block;
  height: ${(props) => (props.newSteps ? '160px' : '112px')};
  left: 0;
  overflow: hidden;
  position: fixed;
  top: ${(props) => (props.newSteps ? '80px' : '128px')};
  transition: width 500ms ease;
  width: 0;
  z-index: 1500;

  ${mixins.xl(`
    width: 300px;
    overflow: visible;
  `)}
`;

export const MenuHighlight = styled.div<StepProps>`
  background-color: white !important;
  border-radius: ${primitives.layout.cornerRadius2}px;
  display: ${(props) => (props.show ? 'block' : 'none')};
  height: 26px;
  left: ${primitives.layout.size2}px;
  padding: ${primitives.layout.size2}px;
  position: absolute;
  top: ${({ index }) => (index - 1) * 50 + 12}px;
  transition: top ${TRANSITION_DURATION} linear;
  width: 250px;
  z-index: 1400;

  ${mixins.xx(`
    width: 280px
  `)}

  ${mixins.xl(`
    width: 265px
  `)}

  > div {
    display: table;
    opacity: 0;
    position: absolute;
    transition: opacity ${TRANSITION_DURATION} linear;

    &.show {
      opacity: 1;
    }

    > div {
      &:first-child {
        display: inline-flex;
        vertical-align: middle;
      }

      &:last-child {
        color: ${primitives.color.gray12};
        display: inline-block;
        margin-left: ${primitives.layout.size2}px;
        max-width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        white-space: nowrap;
      }
    }
  }
`;

export const ArrowIndicatorContainer = styled.div<StepProps>`
  display: ${(props) => (props.show ? undefined : 'none')};
  height: calc(50vh - 390px);
  left: 80px;
  min-height: 50px;
  position: absolute;
  top: ${({ index }) => (index - 1) * 50 + 65}px;
  transition: top ${TRANSITION_DURATION} linear;
  width: calc(50vw - 420px);
  z-index: 2000;
`;

export const Arrow = styled.div`
  height: 100%;
  width: 100%;
  border-left: 3px solid white !important;
  border-bottom: 3px solid white !important;
  border-end-start-radius: 10px;
  display: none;

  :before,
  :after {
    content: '';
    position: absolute;
    width: 3px;
    height: 14px;
    top: -3px;
    background-color: white;
    border-radius: 10px;
  }

  :before {
    left: -4px;
    transform: rotate(45deg);
  }

  :after {
    left: 4px;
    transform: rotate(-45deg);
  }

  ${mixins.xl(`
    display: block;
  `)}
`;

export const Modal = styled(VeneerModal)`
  .vn-modal--content {
    border: 0;
    padding: 0;
    width: calc(100% - 24px - 24px);

    ${mixins.md(`
      width: 640px;
    `)}
  }

  .vn-modal--footer {
    padding: 24px;

    ${mixins.md(`
      padding: 48px;
    `)}
  }
`;

export const ModalButtons = styled.div<ModalButtonsProps>`
  display: flex;
  flex-flow: ${(props) => (props.newSteps ? 'column-reverse' : 'column')};
  gap: 12px;
  justify-content: ${(props) => props.flexDirection || 'space-between'};
  width: 100%;

  ${mixins.md(`
    flex-flow: row;
    gap: 0;
  `)}
`;

export const Title = styled.div`
  margin-bottom: 12px;

  ${mixins.md(`
    margin-bottom: 20px;
  `)}
`;

export const Bookmark = styled.div`
  display: flex;
  margin-top: 20px;
  color: ${primitives.color.hpBlue7};
  display: none;

  ${mixins.md(`
    display: flex;
  `)}
`;
