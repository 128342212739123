import {
  DASHBOARD_PATH,
  PRINTERS_PATH,
  SOLUTIONS_PATH,
  USERS_PATH,
  HELP_CENTER_PATH,
  SETTINGS_PATH
} from './constants';

export const canShowNotificationModal = (pathname) => {
  switch (pathname) {
    case DASHBOARD_PATH:
    case PRINTERS_PATH:
    case SOLUTIONS_PATH:
    case USERS_PATH:
    case HELP_CENTER_PATH:
    case SETTINGS_PATH:
      return true;
  }

  return false;
};

export const userSelectionCookie = 'smbpEmailNotificationConsent';
