import styled from 'styled-components';
import primitives from '@veneer/primitives';
import { mixins } from '@jarvis/react-portal-addons';

type FooterContainerProps = {
  backgroundColor?: string;
};

type FooterContentContainerProps = {
  maxWidth?: string;
};

export const FooterContainer = styled.div<FooterContainerProps>`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
`;

export const FooterContentContainer = styled.div<FooterContentContainerProps>`
  width: 100%;
  max-width: ${(props) => props.maxWidth};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${primitives.layout.size6}px ${primitives.layout.size8}px;
  white-space: normal;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.orbitalFour};
  color: ${primitives.color.gray11};
  text-align: center;

  ${mixins.md(`
    flex-direction: row;
    justify-content: space-between;
    white-space: nowrap;
    text-align: unset;
  `)}
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.orbitalTwo};
  flex-wrap: wrap;
  align-items: center;

  > a {
    color: ${primitives.color.gray11};
    text-decoration: none;
    padding: 2px ${({ theme }) => theme.orbitalTwo};
    &:hover {
      color: ${primitives.color.hpBlue9};
    }
  }

  ${mixins.md(`
    flex-direction: row;
  `)}
`;
