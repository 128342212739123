const locales: { [key: string]: string } = {
  bg: 'bg-bg',
  cs: 'cz-cs',
  da: 'dk-da',
  el: 'gr-el',
  ee: 'ee-et',
  fi: 'fi-fi',
  hr: 'hr-hr',
  hu: 'hu-hu',
  it: 'it-it',
  ja: 'jp-ja',
  ko: 'kr-ko',
  lt: 'lt-lt',
  lv: 'lv-lv',
  pl: 'pl-pl',
  ro: 'ro-ro',
  sk: 'sk-sk',
  sv: 'se-sv',
  tr: 'tr-tr'
};

const getLocale = (
  country: string,
  language: string,
  specialCases: { [key: string]: (country: string) => string | null } = {},
  excludedLanguages: string[] = []
) => {
  if (excludedLanguages.includes(language)) {
    return `${country}-${language}`.toLowerCase();
  }

  if (specialCases[language]) {
    const specialLocale = specialCases[language](country);
    if (specialLocale) {
      return specialLocale;
    }
  }
  if (locales[language]) {
    return locales[language];
  }
  return `${country}-${language}`.toLowerCase();
};

const getHpDotComLinkLocale = (country: string, language: string) => {
  const specialCases: { [key: string]: (country: string) => string | null } = {
    de: (country) => (!['at', 'ch'].includes(country) ? 'de-de' : null),
    en: (country) =>
      ![
        'ca',
        'gb',
        'au',
        'bd',
        'hk',
        'ie',
        'lk',
        'my',
        'nz',
        'ph',
        'sa',
        'sg',
        'za'
      ].includes(country)
        ? 'us-en'
        : null,
    es: (country) =>
      !['ar', 'bo', 'cl', 'co', 'ec', 'mx', 'pe', 'py', 'uy', 've'].includes(
        country
      )
        ? 'es-es'
        : null,
    fr: (country) => (!['ca', 'be', 'ch'].includes(country) ? 'fr-fr' : null),
    nl: (country) => (country === 'be' ? 'be-nl' : 'nl-nl'),
    pt: (country) => (country === 'br' ? 'br-pt' : 'pt-pt'),
    zh: (country) => (country === 'hk' ? 'hk-zh' : 'tw-zh')
  };

  return getLocale(country, language, specialCases);
};

const getHpSupportLinkLocale = (country: string, language: string) => {
  const specialCases: { [key: string]: (country: string) => string | null } = {
    de: (country) => (!['at', 'ch'].includes(country) ? 'de-de' : null),
    en: (country) =>
      ![
        'bg',
        'ca',
        'gb',
        'ee',
        'hr',
        'lt',
        'lv',
        'ro',
        'sk',
        'au',
        'hk',
        'id',
        'ie',
        'my',
        'nz',
        'ph',
        'rs',
        'sg',
        'th',
        'vn',
        'za'
      ].includes(country)
        ? 'us-en'
        : null,
    fr: (country) => (!['ca', 'be', 'ch'].includes(country) ? 'fr-fr' : null),
    zh: (country) => (!['cn', 'tw', 'hk'].includes(country) ? 'fr-fr' : null)
  };
  return getLocale(country, language, specialCases);
};

const getDataRightsLinkLocale = (country: string, language: string) => {
  const specialCases: { [key: string]: (country: string) => string | null } = {
    de: (country) => (!['at', 'ch'].includes(country) ? 'de-de' : null),
    en: (country) => (!['ca', 'gb'].includes(country) ? 'us-en' : null),
    es: (country) => (country === 'ar' ? 'ar-es' : 'es-es'),
    fr: (country) => (!['ca', 'be', 'ch'].includes(country) ? 'fr-fr' : null),
    nb: () => 'no-no',
    pt: () => 'pt-pt',
    nl: (country) => (country === 'be' ? 'be-nl' : 'nl-nl')
  };

  const excludedLanguages: string[] = ['ja', 'ko', 'ru', 'zh'];

  return getLocale(country, language, specialCases, excludedLanguages);
};

export default {
  getDataRightsLinkLocale,
  getHpSupportLinkLocale,
  getHpDotComLinkLocale
};
