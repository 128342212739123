import styled from 'styled-components';
import {
  Group as VeneerGroup,
  IconEnvelope as VeneerIconEnvelope
} from '@veneer/core';
import primitives from '@veneer/primitives';

export const TitleArea = styled.h4`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: ${primitives.layout.size2}px;

  padding-bottom: ${primitives.layout.size5}px;
`;

export const Group = styled(VeneerGroup)`
  justify-content: end;
  width: 100%;
`;

export const IconEnvelope = styled(VeneerIconEnvelope)`
  flex-shrink: 0;

  > * {
    color: ${({ theme }) => theme.color.caution.foreground.default};
  }
`;
