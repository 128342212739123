import { useCallback } from 'react';
import { DeviceCacheApiClient } from '@jarvis/web-stratus-client';
import { useApiCall, useRootContext } from '@jarvis/react-portal-addons';
import { SingleDeviceCacheResponse } from '../schemas/deviceCache';
import { UseApiCallReturn } from '../types/useApiCall';

const useDeviceCacheApiCall = (
  deviceId: string,
  init = true
): UseApiCallReturn<SingleDeviceCacheResponse> => {
  const { shell, stack } = useRootContext();

  const apiCall = useCallback(async () => {
    const deviceCacheClient = new DeviceCacheApiClient(
      stack,
      shell?.v1?.authProvider
    );
    const response = await deviceCacheClient.show(deviceId);

    return response.data;
  }, [stack, shell?.v1?.authProvider, deviceId]);

  return useApiCall({ apiCall, init });
};

export default useDeviceCacheApiCall;
