import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { RootProvider } from '@jarvis/react-portal-addons';
import {
  LD_ANONYMOUS_USER_KEY,
  getLDClientSideID,
  getUniqueUserKey
} from './utils/ldClient';
import App from './App';
import resources from './assets/locale';

const FinishSetup = (props) => {
  const localization = props.shell?.v1?.localization || {};
  const stack = props.shell?.v1?.app?.getPortalStack() || 1;

  const [LDProvider, setLDProvider] = useState(null);
  const [fetchingLD, setFetchingLD] = useState(true);
  const [errorLD, setErrorLD] = useState(false);

  useEffect(() => {
    const fetchFetureFlags = async () => {
      try {
        const Provider = await asyncWithLDProvider({
          clientSideID: getLDClientSideID(),
          options: {
            bootstrap: 'localStorage',
            streaming: false
          },
          reactOptions: {
            useCamelCaseFlagKeys: true
          },
          user: {
            key: getUniqueUserKey() || LD_ANONYMOUS_USER_KEY
          }
        });
        setLDProvider(() => Provider);
      } catch (err) {
        console.error(err);
        setErrorLD(true);
      } finally {
        setFetchingLD(false);
      }
    };
    fetchFetureFlags();
  }, []);

  if (fetchingLD) {
    return <></>;
  }

  if (errorLD) {
    return (
      <RootProvider
        stack={stack}
        shell={props.shell}
        localization={localization}
        resources={resources}
      >
        <App {...props} />
      </RootProvider>
    );
  }

  return (
    <LDProvider>
      <RootProvider
        stack={stack}
        shell={props.shell}
        localization={localization}
        resources={resources}
      >
        <App {...props} />
      </RootProvider>
    </LDProvider>
  );
};

FinishSetup.propTypes = {
  shell: PropTypes.objectOf(PropTypes.any).isRequired,
  properties: PropTypes.shape({
    analyticsActivity: PropTypes.string,
    showPostOobeModals: PropTypes.bool,
    showHpDotComLink: PropTypes.bool,
    showHpSupportLink: PropTypes.bool,
    showHpTermsOfUseLink: PropTypes.bool,
    showHpPrivacyLink: PropTypes.bool,
    showDataRightsNoticeLink: PropTypes.bool
  }).isRequired
};

export default FinishSetup;
