import { useCallback } from 'react';
import { useApiCall, useRootContext } from '@jarvis/react-portal-addons';
import { UserMgtSvcClientv3 } from '@jarvis/web-stratus-client';

const useMeCall = () => {
  const { shell, stack } = useRootContext();
  const { authProvider } = shell.v1;

  const apiCall = useCallback(async () => {
    const client = new UserMgtSvcClientv3(stack, authProvider);
    const response = await client.getCurrentActiveUser();

    return response;
  }, [authProvider, stack]);

  return useApiCall({ apiCall, init: true });
};

export default useMeCall;
