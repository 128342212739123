import styled from 'styled-components';
import { mixins } from '@jarvis/react-portal-addons';
import { Button as VeneerButton, Modal as VeneerModal } from '@veneer/core';
import primitives from '@veneer/primitives';

export const Modal = styled(VeneerModal)`
  .vn-modal--content {
    border: 0;
    padding: 40px;

    ${mixins.sm(`
      width: 560px;
    `)}
  }

  .vn-modal--body {
    justify-content: center;
  }

  .vn-modal--footer {
    display: flex;
    padding: 0;
  }
`;

export const Title = styled.div`
  align-self: flex-start;
  margin-bottom: ${primitives.layout.size4}px;

  @media (max-width: 576px) {
    margin-bottom: ${primitives.layout.size5}px;
    padding-bottom: ${primitives.layout.size4}px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${primitives.layout.size5}px;
  align-items: flex-start;

  ${mixins.sm(`
    align-items: flex-start;
  `)}

  @media (max-width: 576px) {
    align-items: center;
  }
`;

export const PrinterInfoContainer = styled.div`
  background-color: ${primitives.color.cornFlowerBlue1};
  border-radius: ${primitives.layout.cornerRadius4}px;
  display: flex;
  padding-top: ${primitives.layout.size6}px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
`;

export const PrinterImageArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  position: relative;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 576px) {
    align-items: center;
  }
`;

export const PrinterInfoCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${primitives.layout.size3}px;
  align-self: stretch;
  align-items: flex-start;

  @media (max-width: 576px) {
    align-items: center;
  }
`;

export const PrinterImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: -4px;
  position: absolute;
  top: -48px;

  > img {
    width: 200px;
    height: auto;
  }

  &::after {
    content: '';
    width: 120px;
    height: ${primitives.layout.size3}px;
    background-color: ${primitives.color.cornFlowerBlue8};
    opacity: 0.3;
    filter: blur(4px);
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Logo = styled.div`
  width: 48px;
  height: 48px;
  position: absolute;
  left: 0;
  bottom: 0;
`;

export const PrinterNameLocation = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: ${primitives.layout.size5}px;
  padding-top: ${primitives.layout.size6}px;
  padding-right: 0px;
  width: 50%;

  .caption {
    color: ${primitives.color.gray9};
  }

  @media (max-width: 576px) {
    align-items: center;
    padding-left: 0;
    margin-top: 110px;
  }
`;

export const PrinterFriendlyName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  padding: ${primitives.layout.size6}px 0 ${primitives.layout.size6}px
    ${primitives.layout.size5}px;
  align-self: start;

  @media (max-width: 576px) {
    align-self: center;
    padding: 0 !important;
    margin: ${primitives.layout.size5}px ${primitives.layout.size4}px
      ${primitives.layout.size4}px ${primitives.layout.size4}px;
    width: -moz-available;
  }
`;

export const ButtonGoToPrinter = styled(VeneerButton)`
  display: flex;
  height: 36px;

  @media (max-width: 576px) {
    align-self: center;
    width: inherit;
  }
`;

export const InLineNotificationContainer = styled.div`
  display: flex;
  min-height: 64px;
  padding: 0 ${primitives.layout.size2}px 0 ${primitives.layout.size3}px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: ${primitives.layout.cornerRadius3}px;
  width: auto;
  background: ${primitives.color.lavender1};
`;

export const DescriptionText = styled.div`
  align-self: stretch;
  align-content: center;
  padding: ${primitives.layout.size3}px ${primitives.layout.size3}px
    ${primitives.layout.size3}px 0px;
`;

export const IconContainer = styled.div`
  width: 36px;
  height: 36px;
  padding: ${primitives.layout.size3}px ${primitives.layout.size3}px 0 0;
  gap: 10px;

  @media (max-width: 576px) {
    padding: ${primitives.layout.size3}px ${primitives.layout.size3}px 0 0;
    margin-bottom: 10px;
  }
`;
