import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Modal } from '@veneer/core';
import {
  cookies,
  useI18n,
  useMediaQuery,
  mixins,
  useCallSuccess,
  useRootContext
} from '@jarvis/react-portal-addons';
import useUserPreferencesListCall from '../../hooks/useUserPreferencesListCall';
import useMeCall from '../../hooks/useMeCall';
import useUserPreferencesCreateCall from '../../hooks/useUserPreferencesCreateCall';
import { Group, IconEnvelope, TitleArea } from './styles';
import {
  notificationsModalButtonNotNow,
  notificationsModalButtonTurnOn,
  notificationsModalWindowDisplayed,
  publishEvent
} from 'src/utils/analytics';
import {
  canShowNotificationModal,
  userSelectionCookie
} from '../../utils/emailNotification';

const { deleteCookie, getCookie, setCookie } = cookies;

type EmailNotificationModalProps = {
  onClose: () => void;
  onHide: () => void;
};

const makeCustomData = (isEmailEnabled) => {
  return btoa(
    JSON.stringify({
      assessRate: isEmailEnabled ? 'oncePerDay' : 'never',
      notificationSettings: [
        {
          category: 'deviceAlert',
          adminAlert: isEmailEnabled
        }
      ]
    })
  );
};

const EmailNotificationModal = ({
  onClose,
  onHide
}: EmailNotificationModalProps) => {
  const { shell } = useRootContext();
  const { navigation, tenantHandlerInterface } = shell.v1;

  const [show, setShow] = useState(false);
  const [wasShown, setWasShown] = useState(false);
  const [isEmailAllowed, setIsEmailAllowed] = useState(false);
  const [userId, setUserId] = useState(null);
  const [pathname, setPathname] = useState(navigation.location.pathname);
  const [hasCookie, setHasCookie] = useState(false);
  const { t } = useI18n();
  const isExtraSmall = useMediaQuery(mixins.mediaQueries.xs);

  const meCall = useMeCall();
  const userPreferencesListCall = useUserPreferencesListCall({
    init: false
  });

  const onMeCallSuccess = useCallback(
    (data) => {
      const { resourceId } = data;

      if (!hasCookie) {
        userPreferencesListCall.makeApiCall({
          userId: resourceId,
          tenantId: tenantHandlerInterface.getTenantId()
        });
      }

      setUserId(resourceId);
    },
    [hasCookie, tenantHandlerInterface, userPreferencesListCall]
  );

  useCallSuccess({
    call: meCall,
    onSuccess: onMeCallSuccess
  });

  const createPreferenceCall = useUserPreferencesCreateCall({
    init: false
  });
  const makeCreatePreferenceCall = createPreferenceCall.makeApiCall;

  const onAllowClicked = useCallback(() => {
    makeCreatePreferenceCall({
      tenantId: tenantHandlerInterface.getTenantId(),
      userId,
      status: 'ENABLED',
      level: 'USER-TENANT',
      customData: makeCustomData(true)
    });
    setIsEmailAllowed(true);
    setShow(false);
    onHide();
  }, [makeCreatePreferenceCall, onHide, tenantHandlerInterface, userId]);

  const onDenyClicked = useCallback(() => {
    makeCreatePreferenceCall({
      tenantId: tenantHandlerInterface.getTenantId(),
      userId,
      status: 'ENABLED',
      level: 'USER-TENANT',
      customData: makeCustomData(false)
    });
    setIsEmailAllowed(false);
    setShow(false);
    onHide();
  }, [makeCreatePreferenceCall, onHide, tenantHandlerInterface, userId]);

  const onCreatePreferenceSuccess = useCallback(() => {
    if (hasCookie) {
      deleteCookie(userSelectionCookie);
    }

    onClose();
  }, [hasCookie, onClose]);

  useCallSuccess({
    call: createPreferenceCall,
    onSuccess: onCreatePreferenceSuccess
  });

  // handles when the call to create the user preference fails
  useEffect(() => {
    const hasError = !!createPreferenceCall.error;
    const status = createPreferenceCall.error?.response?.status;

    if (!hasCookie && hasError && status !== 409) {
      setCookie(userSelectionCookie, isEmailAllowed);
    }

    if (hasCookie && hasError && status === 409) {
      deleteCookie(userSelectionCookie);
    }

    if (hasError) {
      onClose();
    }
  }, [createPreferenceCall.error, isEmailAllowed, hasCookie, onClose]);

  useEffect(() => {
    if (show) {
      publishEvent(notificationsModalWindowDisplayed);
    }
  }, [show]);

  useEffect(() => {
    const userSelection = getCookie(userSelectionCookie);

    if (userSelection && userId) {
      makeCreatePreferenceCall({
        tenantId: tenantHandlerInterface.getTenantId(),
        userId,
        status: 'ENABLED',
        level: 'USER-TENANT',
        customData: makeCustomData(userSelection === 'true')
      });
    }
  }, [makeCreatePreferenceCall, tenantHandlerInterface, userId]);

  // handles when to show the email notification modal
  useEffect(() => {
    const userSelection = getCookie(userSelectionCookie);
    if (userSelection) {
      setHasCookie(true);
      return;
    }

    setHasCookie(false);

    const listCallFulfilled =
      !userPreferencesListCall.pending && userPreferencesListCall.success;
    if (!listCallFulfilled) {
      return;
    }

    const listCallStatus = userPreferencesListCall.data?.status;
    if (listCallStatus !== 204) {
      onClose();
      return;
    }

    if (wasShown) {
      return;
    }

    if (!canShowNotificationModal(pathname)) {
      return;
    }

    setShow(true);
    setWasShown(true);
  }, [
    onClose,
    pathname,
    userPreferencesListCall.data?.status,
    userPreferencesListCall.pending,
    userPreferencesListCall.success,
    wasShown
  ]);

  useEffect(() => {
    if (userPreferencesListCall.error) {
      onClose();
    }
  }, [onClose, userPreferencesListCall.error]);

  useEffect(() => {
    return navigation.listen(({ pathname }) => {
      setPathname(pathname);
    });
  }, [navigation]);

  useEffect(() => {
    if (hasCookie) {
      onHide();
    }
  }, [hasCookie, onHide]);

  const buttons = useMemo(() => {
    const buttons = [
      <Button
        appearance="secondary"
        data-testid="deny-button"
        key="deny-button"
        onClick={() => {
          onDenyClicked();
          publishEvent(notificationsModalButtonNotNow);
        }}
      >
        {t('emailNotificationModal.deny')}
      </Button>,
      <Button
        appearance="secondary"
        data-testid="allow-button"
        key="allow-button"
        onClick={() => {
          onAllowClicked();
          publishEvent(notificationsModalButtonTurnOn);
        }}
      >
        {t('emailNotificationModal.allow')}
      </Button>
    ];

    if (isExtraSmall) {
      return buttons.slice().reverse();
    }

    return buttons;
  }, [t, isExtraSmall, onDenyClicked, onAllowClicked]);

  return (
    <Modal
      data-testid="email-notification-modal"
      closeOnBlur={false}
      expanded
      footer={
        <Group
          orientation={isExtraSmall ? 'vertical' : 'horizontal'}
          spacing={4}
        >
          {buttons}
        </Group>
      }
      maxWidth="556px"
      onClose={onClose}
      show={show}
    >
      <TitleArea>
        <IconEnvelope
          data-testid="title-icon"
          filled
          size={36}
        />
        <p data-testid="title-text">{t('emailNotificationModal.title')}</p>
      </TitleArea>
      <p
        className="body"
        data-testid="description-text"
      >
        {t('emailNotificationModal.description')}
      </p>
      <p
        className="body"
        data-testid="description-path-text"
        dangerouslySetInnerHTML={{
          __html: t('emailNotificationModal.descriptionPath')
        }}
      />
    </Modal>
  );
};

export default EmailNotificationModal;
