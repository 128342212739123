import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  IconBookmark,
  IconHome,
  IconPeople,
  IconPrinter
} from '@veneer/core';
import { useI18n } from '@jarvis/react-portal-addons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Step1Image from '../../assets/images/step1.svg';
import Step2Image from '../../assets/images/step2.svg';
import Step3Image from '../../assets/images/step3.svg';
import StepWelcome from '../../assets/animations/welcome.gif';
import StepHome from '../../assets/animations/home.gif';
import StepPrinters from '../../assets/animations/printers.gif';
import StepUsers from '../../assets/animations/users.gif';
import {
  ManagePrinterTutorialButtonBack,
  ManagePrinterTutorialButtonDismiss,
  ManagePrinterTutorialButtonNext,
  ManagePrinterTutorialModalDisplayed,
  ManageUsersTutorialButtonBack,
  ManageUsersTutorialButtonDismiss,
  ManageUsersTutorialButtonGetStarted,
  ManageUsersTutorialModalDisplayed,
  NotificationTutorialButtonBack,
  NotificationTutorialButtonDismiss,
  NotificationTutorialButtonNext,
  NotificationTutorialModalDisplayed,
  WelcomeTutorialButtonDismiss,
  WelcomeTutorialButtonNext,
  WelcomeTutorialButtonSkip,
  WelcomeTutorialModalDisplayed,
  publishEvent
} from '../../utils/analytics';
import {
  Arrow,
  ArrowIndicatorContainer,
  Bookmark,
  Content,
  FadeInOut,
  Header,
  HeaderContent,
  HeaderText,
  Indicator,
  MenuHighlight,
  MenuHighlightContent,
  Modal,
  ModalButtons,
  Title
} from './styles';

type appearanceType = 'primary' | 'secondary' | 'ghost';

const dismissTutorialEventsLegacySteps = {
  0: WelcomeTutorialButtonDismiss,
  1: ManagePrinterTutorialButtonDismiss,
  2: ManageUsersTutorialButtonDismiss
};

const dismissTutorialEventsNewSteps = {
  0: WelcomeTutorialButtonDismiss,
  1: NotificationTutorialButtonDismiss,
  2: ManagePrinterTutorialButtonDismiss,
  3: ManageUsersTutorialButtonDismiss
};

const nextButtonEventsLegacySteps = {
  0: WelcomeTutorialButtonNext,
  1: ManagePrinterTutorialButtonNext
};

const nextButtonEventsNewSteps = {
  0: WelcomeTutorialButtonNext,
  1: NotificationTutorialButtonNext,
  2: ManagePrinterTutorialButtonNext
};

const backButtonEventsLegacySteps = {
  1: ManagePrinterTutorialButtonBack,
  2: ManageUsersTutorialButtonBack
};

const backButtonEventsNewSteps = {
  1: NotificationTutorialButtonBack,
  2: ManagePrinterTutorialButtonBack,
  3: ManageUsersTutorialButtonBack
};

const modalDisplayedEventsLegacySteps = {
  0: WelcomeTutorialModalDisplayed,
  1: ManagePrinterTutorialModalDisplayed,
  2: ManageUsersTutorialModalDisplayed
};

const modalDisplayedEventsNewSteps = {
  0: WelcomeTutorialModalDisplayed,
  1: NotificationTutorialModalDisplayed,
  2: ManagePrinterTutorialModalDisplayed,
  3: ManageUsersTutorialModalDisplayed
};

const Tutorial = ({ onFinish }) => {
  const { t } = useI18n();

  const { finishSetupPrinterAddedModal } = useFlags();

  const [showTutorialModals, setShowTutorialModals] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);

  const onClose = async () => {
    // Wrapped in a promise to ensure the modal is closed before the onFinish function is called
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        setShowTutorialModals(false);
        resolve(); // Resolves the promise after the modal is closed
      }, 200);
    });
  };

  const resolveImage = (img: HTMLImageElement) => {
    return new Promise<void>((resolve, reject) => {
      img.onload = () => resolve();
      img.onerror = (err) => reject(err);
    });
  };

  // preload images
  useEffect(() => {
    const animations = [
      StepWelcome.toString(),
      StepHome.toString(),
      StepPrinters.toString(),
      StepUsers.toString()
    ];

    const images = [
      Step1Image.toString(),
      Step2Image.toString(),
      Step3Image.toString()
    ];

    const assets = finishSetupPrinterAddedModal ? animations : images;

    const loadImage = (image: string) => {
      const img = new Image();
      img.src = image;
      return resolveImage(img);
    };

    const loadImages = async () => {
      const promises = assets.map((image) => loadImage(image));
      await Promise.all(promises);
    };

    loadImages();
  }, [finishSetupPrinterAddedModal]);

  useEffect(() => {
    const modalDisplayedEvents = finishSetupPrinterAddedModal
      ? modalDisplayedEventsNewSteps
      : modalDisplayedEventsLegacySteps;
    publishEvent(modalDisplayedEvents[currentStep]);
  }, [currentStep, finishSetupPrinterAddedModal]);

  const LegacySteps = useMemo(
    () => [
      {
        backgroundColor: '#E7EEF6',
        buttons: {
          right: {
            label: t('buttons.next')
          }
        },
        description: t('steps.stepOne.description'),
        flexDirection: 'end',
        headerImage: {
          src: Step1Image
        },
        id: 'first',
        title: t('steps.stepOne.title')
      },
      {
        backgroundColor: '#EEEDF6',
        buttons: {
          left: {
            label: t('buttons.back')
          },
          right: {
            label: t('buttons.next')
          }
        },
        description: t('steps.stepTwo.description')
          .replace('{{tagReplaceStart}}', '<b>')
          .replace('{{tagReplaceEnd}}', '</b>'),
        headerImage: {
          src: Step2Image,
          text: (
            <HeaderText newSteps={finishSetupPrinterAddedModal}>
              <div className="label">{t('steps.headerImage.print')}</div>
              <div className="label">{t('steps.headerImage.scan')}</div>
              <div className="label">{t('steps.headerImage.fax')}</div>
              <div className="label">{t('steps.headerImage.solutions')}</div>
              <div className="label">{t('steps.headerImage.supplies')}</div>
            </HeaderText>
          )
        },
        highlightMenu: {
          text: t('steps.highlightMenu.printers'),
          icon: <IconPrinter size={24} />
        },
        id: 'third',
        title: t('steps.stepTwo.title')
      },
      {
        backgroundColor: '#E6F5EE',
        buttons: {
          left: {
            label: t('buttons.back')
          },
          right: {
            appearance: 'primary',
            label: t('buttons.start')
          }
        },
        description: t('steps.stepThree.description')
          .replace('{{tagReplaceStart}}', '<b>')
          .replace('{{tagReplaceEnd}}', '</b>'),
        headerImage: {
          src: Step3Image
        },
        highlightMenu: {
          text: t('steps.highlightMenu.users'),
          icon: <IconPeople size={24} />
        },
        id: 'fourth',
        title: t('steps.stepThree.title')
      }
    ],
    [t, finishSetupPrinterAddedModal]
  );

  const NewSteps = useMemo(
    () => [
      {
        ...LegacySteps[0],
        title: t('steps.stepWelcome.title'),
        description: t('steps.stepWelcome.description'),
        bookmark: t('steps.stepWelcome.bookmark'),
        headerImage: {
          src: StepWelcome
        },
        flexDirection: 'space-between',
        buttons: {
          right: {
            appearance: 'primary',
            label: t('buttons.takeTour')
          },
          left: {
            label: t('buttons.skip')
          }
        }
      },
      {
        backgroundColor: '#FFF1D6',
        buttons: {
          left: {
            label: t('buttons.back')
          },
          right: {
            appearance: 'primary',
            label: t('buttons.continue')
          }
        },
        description: t('steps.stepHome.description')
          .replace('{{tagReplaceStart}}', '<b>')
          .replace('{{tagReplaceEnd}}', '</b>'),
        headerImage: {
          src: StepHome
        },
        highlightMenu: {
          text: t('steps.highlightMenu.home'),
          icon: <IconHome size={24} />
        },
        id: 'second',
        title: t('steps.stepHome.title')
      },
      {
        ...LegacySteps[1],
        title: t('steps.stepManage.title'),
        description: t('steps.stepManage.description')
          .replace('{{tagReplaceStart}}', '<b>')
          .replace('{{tagReplaceEnd}}', '</b>'),
        headerImage: {
          ...LegacySteps[1].headerImage,
          src: StepPrinters
        },
        buttons: {
          ...LegacySteps[1].buttons,
          right: {
            appearance: 'primary',
            label: t('buttons.continue')
          }
        }
      },
      {
        ...LegacySteps[2],
        title: t('steps.stepInvite.title'),
        description: t('steps.stepInvite.description')
          .replace('{{tagReplaceStart}}', '<b>')
          .replace('{{tagReplaceEnd}}', '</b>'),
        headerImage: {
          src: StepUsers
        },
        buttons: {
          ...LegacySteps[1].buttons,
          right: {
            appearance: 'primary',
            label: t('buttons.continue')
          }
        }
      }
    ],
    [t, LegacySteps]
  );

  const Steps = useMemo(
    () => (finishSetupPrinterAddedModal ? NewSteps : LegacySteps),
    [finishSetupPrinterAddedModal, NewSteps, LegacySteps]
  );

  const HeaderImages = useMemo(
    () =>
      Steps.map((step, idx) => (
        <div
          className={`${idx === currentStep ? 'show' : 'hide'}`}
          key={`${step.id}-step-key`}
        >
          <img
            alt={`${step.id}-step`}
            src={Steps[idx].headerImage.src.toString()}
          />
        </div>
      )),
    [Steps, currentStep]
  );

  const StepMessages = useMemo(
    () => (
      <FadeInOut>
        {Steps.map((step, idx) => (
          <div
            className={`${idx === currentStep ? 'show' : 'hide'}`}
            key={`${step.id}-step-text`}
          >
            <Title className="subtitle-large">{step.title}</Title>
            <div
              className="body"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: step.description }}
            />
            {step.bookmark ? (
              <Bookmark>
                <IconBookmark
                  size={24}
                  color="hpBlue9"
                />
                {step.bookmark}
              </Bookmark>
            ) : (
              <></>
            )}
          </div>
        ))}
      </FadeInOut>
    ),
    [Steps, currentStep]
  );

  const Stepper = useMemo(
    () => (
      <Indicator newSteps={finishSetupPrinterAddedModal}>
        {Steps.map((item, idx) => (
          <div
            key={item.id}
            {...(idx === currentStep ? { className: 'current' } : {})}
          />
        ))}
      </Indicator>
    ),
    [Steps, currentStep, finishSetupPrinterAddedModal]
  );

  const handleLeftButtonClick = useCallback(
    async (ev) => {
      ev.stopPropagation();
      if (currentStep > 0) {
        const backButtonEvents = finishSetupPrinterAddedModal
          ? backButtonEventsNewSteps
          : backButtonEventsLegacySteps;
        publishEvent(backButtonEvents[currentStep]);
        setCurrentStep(currentStep - 1);
      } else {
        publishEvent(WelcomeTutorialButtonSkip);
        await onClose();
        setCurrentStep(0);
        onFinish?.();
      }
    },
    [currentStep, onFinish, finishSetupPrinterAddedModal]
  );

  const handleRightButtonClick = useCallback(
    async (ev) => {
      ev.stopPropagation();
      if (currentStep >= Steps.length - 1) {
        publishEvent(ManageUsersTutorialButtonGetStarted);
        await onClose();
        onFinish?.();
        setCurrentStep(0);
      } else {
        const nextButtonEvents = finishSetupPrinterAddedModal
          ? nextButtonEventsNewSteps
          : nextButtonEventsLegacySteps;
        publishEvent(nextButtonEvents[currentStep]);
        setCurrentStep(currentStep + 1);
      }
    },
    [Steps.length, currentStep, onFinish, finishSetupPrinterAddedModal]
  );

  return (
    <>
      {showTutorialModals && (
        <MenuHighlightContent newSteps={finishSetupPrinterAddedModal}>
          <MenuHighlight
            index={currentStep}
            show={currentStep !== 0}
          >
            {Steps.slice(1).map((item, idx) => (
              <div
                {...(idx === currentStep - 1 ? { className: 'show' } : {})}
                key={`${item.id}-highlight-menu-key`}
              >
                <div>{item.highlightMenu.icon}</div>
                <div>{item.highlightMenu.text}</div>
              </div>
            ))}
          </MenuHighlight>
          {finishSetupPrinterAddedModal && (
            <ArrowIndicatorContainer
              show={currentStep !== 0}
              index={currentStep}
            >
              <Arrow />
            </ArrowIndicatorContainer>
          )}
        </MenuHighlightContent>
      )}
      <Modal
        align="start"
        id="finish-setup-tutorial-modals"
        closeButton
        closeOnBlur={false}
        maxWidth="auto"
        onClose={async (ev) => {
          ev.stopPropagation();
          const dismissTutorialEvents = finishSetupPrinterAddedModal
            ? dismissTutorialEventsNewSteps
            : dismissTutorialEventsLegacySteps;
          publishEvent(dismissTutorialEvents[currentStep]);
          await onClose();
          setCurrentStep(0);
          onFinish?.();
        }}
        show={showTutorialModals}
        footer={
          <ModalButtons
            {...(Steps[currentStep].flexDirection
              ? { flexDirection: Steps[currentStep].flexDirection }
              : {})}
            newSteps={finishSetupPrinterAddedModal}
          >
            {Steps[currentStep].buttons.left && (
              <Button
                appearance="secondary"
                data-testid={`step-${currentStep + 1}-previous-btn`}
                onClick={handleLeftButtonClick}
              >
                {Steps[currentStep].buttons.left.label}
              </Button>
            )}
            <Button
              appearance={
                (Steps[currentStep].buttons.right
                  .appearance as appearanceType) || 'secondary'
              }
              data-testid={`step-${currentStep + 1}-next-btn`}
              onClick={handleRightButtonClick}
            >
              {Steps[currentStep].buttons.right.label}
            </Button>
          </ModalButtons>
        }
      >
        <>
          <Header backgroundColor={Steps[currentStep].backgroundColor}>
            <HeaderContent newSteps={finishSetupPrinterAddedModal}>
              <FadeInOut newSteps={finishSetupPrinterAddedModal}>
                {HeaderImages}
              </FadeInOut>
              {Stepper}
            </HeaderContent>
          </Header>
          <Content>{StepMessages}</Content>
        </>
      </Modal>
    </>
  );
};

Tutorial.propTypes = {
  onFinish: PropTypes.func
};

Tutorial.defaultProps = {
  onFinish: null
};

export default Tutorial;
