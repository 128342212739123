import { useCallback } from 'react';
import { DeviceShadowApiClient } from '@jarvis/web-stratus-client';
import { useApiCall, useRootContext } from '@jarvis/react-portal-addons';
import { SystemConfigurationResponse } from '../schemas/systemConfiguration';
import { UseApiCallReturn } from '../types/useApiCall';

type Params = {
  cloudId: string;
};

const useGetSystemConfiguration = ({
  cloudId
}: Params): UseApiCallReturn<SystemConfigurationResponse> => {
  const { shell, stack } = useRootContext();

  const apiCall = useCallback(async () => {
    const client = new DeviceShadowApiClient(stack, shell?.v1?.authProvider);
    const response = await client.getSystemConfiguration({ cloudId });
    return response.data;
  }, [cloudId, shell?.v1?.authProvider, stack]);

  return useApiCall({ apiCall });
};

export default useGetSystemConfiguration;
