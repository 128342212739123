import React, { useEffect, useMemo, useState } from 'react';
import {
  BlackHPPlus,
  LoaderWidget,
  SmartTooltip,
  useI18n,
  useRootContext
} from '@jarvis/react-portal-addons';
import { IconChevronRight, IconLightbulb } from '@veneer/core';
import NoImageAvailable from '../../assets/images/no-image-available.svg';
import {
  ContentContainer,
  Modal,
  Logo,
  PrinterImageContainer,
  PrinterInfoContainer,
  PrinterNameLocation,
  Title,
  ButtonGoToPrinter,
  InLineNotificationContainer,
  IconContainer,
  DescriptionText,
  PrinterImageArea,
  ButtonContainer,
  PrinterFriendlyName
} from './styles';
import useDeviceCacheApiCall from '../../hooks/useDeviceCacheApiCall';
import useGetSystemConfigurationApiCall from '../../hooks/useSystemConfigurationApiCall';
import {
  getPrinterFriendlyName,
  getPrinterImage,
  getPrinterLocation,
  isPrinterHpPlus
} from '../../utils/printer';
import {
  NewPrinterAddedModalDisplayed,
  NewPrinterAddedButtonDismiss,
  publishEvent
} from '../../utils/analytics';
import primitives from '@veneer/primitives';

type NewPrinterAddedModalProps = {
  printerAddedCookieInfo: {
    cloudID?: string;
    modelName?: string;
  };
  onFinish: () => void;
};

const NewPrinterAddedModal = ({
  printerAddedCookieInfo,
  onFinish
}: NewPrinterAddedModalProps) => {
  const { t } = useI18n();
  const { shell } = useRootContext();
  const navigation = shell?.v1?.navigation;
  const { cloudID: cloudId, modelName } = printerAddedCookieInfo;

  const [deviceCacheTimeout, setDeviceCacheTimeout] = useState(false);
  const [systemConfigTimeout, setSystemConfigTimeout] = useState(false);
  const [printerImgSrc, setPrinterImgSrc] = useState(null);
  const [printerFriendlyName, setPrinterFriendlyName] = useState(null);
  const [printerLocation, setPrinterLocation] = useState(null);
  const [showPrinterAddedModal, setShowPrinterAddedModal] = useState(true);
  const [callsTimeout, setCallsTimeout] = useState(null);

  const {
    data: printerDeviceCacheData,
    error: printerDeviceCacheError,
    success: printerDeviceCacheSuccess
  } = useDeviceCacheApiCall(cloudId);

  const {
    data: printerSystemConfigData,
    pending: printerSystemConfigPending,
    error: printerSystemConfigError,
    success: printerSystemConfigSuccess
  } = useGetSystemConfigurationApiCall({ cloudId });

  const printerDeviceCacheFulfilled = useMemo(
    () => printerDeviceCacheError || printerDeviceCacheSuccess,
    [printerDeviceCacheError, printerDeviceCacheSuccess]
  );

  const printerSystemConfigFulfilled = useMemo(
    () => printerSystemConfigError || printerSystemConfigSuccess,
    [printerSystemConfigError, printerSystemConfigSuccess]
  );

  useEffect(() => {
    publishEvent(NewPrinterAddedModalDisplayed);

    // Pre-fetch fallback image
    const fallbackImage = new Image();
    fallbackImage.src = NoImageAvailable;

    // Set timeout for network calls to 3 seconds
    const id = setTimeout(() => {
      setCallsTimeout(true);
    }, 3000);

    return () => {
      clearTimeout(id);
    };
  }, []);

  useEffect(() => {
    if (callsTimeout) {
      if (!printerDeviceCacheFulfilled) {
        setDeviceCacheTimeout(true);
      }
      if (!printerSystemConfigFulfilled) {
        setSystemConfigTimeout(true);
      }
    }
  }, [callsTimeout, printerDeviceCacheFulfilled, printerSystemConfigFulfilled]);

  useEffect(() => {
    if (deviceCacheTimeout) {
      setPrinterImgSrc(NoImageAvailable);
      return;
    }

    if (printerDeviceCacheFulfilled) {
      const deviceCacheImage = getPrinterImage(printerDeviceCacheData);
      const img = new Image();
      img.onload = () => {
        setPrinterImgSrc(deviceCacheImage);
      };
      img.onerror = () => {
        setPrinterImgSrc(NoImageAvailable);
      };
      img.src = deviceCacheImage;
    }
  }, [deviceCacheTimeout, printerDeviceCacheData, printerDeviceCacheFulfilled]);

  const printerModel = useMemo(() => {
    const deviceCacheModelName =
      printerDeviceCacheData?.identity?.makeAndModel?.name;

    if (
      printerFriendlyName === deviceCacheModelName ||
      printerFriendlyName === modelName
    ) {
      return '';
    }

    if (deviceCacheModelName) {
      return deviceCacheModelName;
    }

    if (printerDeviceCacheFulfilled && modelName) {
      return modelName;
    }

    return modelName;
  }, [
    modelName,
    printerDeviceCacheData?.identity?.makeAndModel?.name,
    printerDeviceCacheFulfilled,
    printerFriendlyName
  ]);

  useEffect(() => {
    if (systemConfigTimeout) {
      return;
    }

    const friendlyName = getPrinterFriendlyName(printerSystemConfigData);
    const location = getPrinterLocation(printerSystemConfigData);

    if (friendlyName) {
      setPrinterFriendlyName(friendlyName);
    } else if (printerModel) {
      setPrinterFriendlyName(printerModel);
    }

    if (location) {
      setPrinterLocation(location);
    }
  }, [printerModel, printerSystemConfigData, systemConfigTimeout]);

  const onClose = () => {
    onFinish();
    setShowPrinterAddedModal(false);
  };

  return (
    <Modal
      id="finish-setup-printer-added-modal"
      show={showPrinterAddedModal}
      closeButton
      maxWidth="auto"
      onClose={() => {
        publishEvent(NewPrinterAddedButtonDismiss);
        onClose();
      }}
      footer={
        <InLineNotificationContainer data-testid="inlineNotification-container">
          <IconContainer>
            <IconLightbulb
              size="24px"
              filled
              color={primitives.color.lavender8}
            />
          </IconContainer>
          <DescriptionText
            className="label"
            data-testid="footer-description-text"
          >
            {t('printerAddedModal.footer')}
          </DescriptionText>
        </InLineNotificationContainer>
      }
    >
      <ContentContainer>
        <Title
          className="title-regular"
          data-testid="printer-added-title"
        >
          {t('printerAddedModal.title')}
        </Title>
        <PrinterInfoContainer>
          {!deviceCacheTimeout &&
          !systemConfigTimeout &&
          (!printerDeviceCacheFulfilled ||
            printerSystemConfigPending ||
            !printerImgSrc) ? (
            <LoaderWidget
              data-testid="loader-widget"
              fullscreen
            />
          ) : (
            <PrinterImageArea>
              <PrinterImageContainer data-testid="printer-image-container">
                <img
                  src={printerImgSrc}
                  alt="Printer"
                />
                {!deviceCacheTimeout &&
                isPrinterHpPlus(printerDeviceCacheData) ? (
                  <Logo>
                    <BlackHPPlus
                      height="100%"
                      width="100%"
                      alt="HP Plus"
                    />
                  </Logo>
                ) : null}
              </PrinterImageContainer>
              <PrinterNameLocation>
                <SmartTooltip
                  content={printerFriendlyName}
                  placement="top"
                >
                  <PrinterFriendlyName
                    className="subtitle-regular"
                    data-testid="friendly-name"
                  >
                    {printerFriendlyName}
                  </PrinterFriendlyName>
                </SmartTooltip>
                <div
                  className="caption"
                  data-testid="model-name"
                >
                  {printerModel}
                </div>
                <div className="caption">{printerLocation}</div>
              </PrinterNameLocation>
              <ButtonContainer>
                <ButtonGoToPrinter
                  data-testid="go-to-printer"
                  appearance="primary"
                  trailingIcon={<IconChevronRight size="24" />}
                  onClick={() => {
                    onClose();
                    navigation.push(`/printers/${cloudId}`);
                  }}
                  className="label"
                >
                  {t('printerAddedModal.button')}
                </ButtonGoToPrinter>
              </ButtonContainer>
            </PrinterImageArea>
          )}
        </PrinterInfoContainer>
      </ContentContainer>
    </Modal>
  );
};

export default NewPrinterAddedModal;
