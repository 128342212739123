import React from 'react';
import { useI18n, useRootContext } from '@jarvis/react-portal-addons';
import footerUtils from '../../utils/footer';
import {
  footerDataRightsNoticeLink,
  footerHpDotComLink,
  footerHpPrivacyLink,
  footerHpSupportLink,
  footerTermsOfUseLink,
  publishEvent
} from '../../utils/analytics';
import {
  FooterContainer,
  FooterContentContainer,
  LinksContainer
} from './styles';

const {
  getHpDotComLinkLocale,
  getHpSupportLinkLocale,
  getDataRightsLinkLocale
} = footerUtils;

type FooterProps = {
  analyticsActivity?: string;
  backgroundColor?: string;
  maxWidth?: string;
  showHpDotComLink?: boolean;
  showHpSupportLink?: boolean;
  showHpTermsOfUseLink?: boolean;
  showHpPrivacyLink?: boolean;
  showDataRightsNoticeLink?: boolean;
};

const Footer = ({
  analyticsActivity = 'Portal-v01',
  backgroundColor = 'transparent',
  maxWidth = '1660px',
  showHpDotComLink,
  showHpSupportLink,
  showHpTermsOfUseLink,
  showHpPrivacyLink,
  showDataRightsNoticeLink
}: FooterProps) => {
  const { t } = useI18n();
  const { localization } = useRootContext();

  const { country, language } = localization;

  return (
    <FooterContainer
      className="caption"
      backgroundColor={backgroundColor}
    >
      <FooterContentContainer maxWidth={maxWidth}>
        <p>
          {t('footer.copyRight').replace(
            '{{currentYear}}',
            new Date().getFullYear()
          )}
        </p>
        <LinksContainer>
          {showHpDotComLink && (
            <a
              href={`https://www.hp.com/${getHpDotComLinkLocale(
                country,
                language
              )}/home.html`}
              target="_blank"
              rel="noreferrer"
              onClick={() =>
                publishEvent(footerHpDotComLink(analyticsActivity))
              }
            >
              HP.com
            </a>
          )}
          {showHpSupportLink && (
            <a
              href={`https://support.hp.com/${getHpSupportLinkLocale(
                country,
                language
              )}`}
              target="_blank"
              rel="noreferrer"
              onClick={() =>
                publishEvent(footerHpSupportLink(analyticsActivity))
              }
            >
              {t('footer.hpSupport')}
            </a>
          )}
          {showHpTermsOfUseLink && (
            <a
              href={`https://hpsmart.com/${country}/${language}/admin-tou`}
              target="_blank"
              rel="noreferrer"
              onClick={() =>
                publishEvent(footerTermsOfUseLink(analyticsActivity))
              }
            >
              {t('footer.hpTermsOfUse')}
            </a>
          )}
          {showHpPrivacyLink && (
            <a
              href="https://hp.com/go/privacy"
              target="_blank"
              rel="noreferrer"
              onClick={() =>
                publishEvent(footerHpPrivacyLink(analyticsActivity))
              }
            >
              {t('footer.hpPrivacy')}
            </a>
          )}
          {showDataRightsNoticeLink &&
            !['ja', 'ko', 'ru', 'zh'].includes(language) && (
              <a
                href={`https://www.hp.com/${getDataRightsLinkLocale(
                  country,
                  language
                )}/personal-data-rights.html`}
                target="_blank"
                rel="noreferrer"
                onClick={() =>
                  publishEvent(footerDataRightsNoticeLink(analyticsActivity))
                }
              >
                {t('footer.personalDataRightsNotice')}
              </a>
            )}
        </LinksContainer>
      </FooterContentContainer>
    </FooterContainer>
  );
};

export default Footer;
