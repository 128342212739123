import React from 'react';
import SMBFinishSetup from '../src';
import ShellV1Type from '../src/types/shell/v1';
import ShellV2Type from '../src/types/shell/v2';
import projectNames from './configs/projectNames';

type ShellType = {
  v1: ShellV1Type;
  v2: ShellV2Type;
};
declare global {
  interface Window {
    Shell: ShellType;
  }
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */

const Root = (props) => {
  const shellProps = (window.Shell || {}) as ShellType;

  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <SMBFinishSetup
        shell={shellProps}
        {...props}
      />
    </section>
  );
};

export default Root;
