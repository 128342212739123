import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { cookies, useRootContext } from '@jarvis/react-portal-addons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  DASHBOARD_PATH,
  EMAIL_NOTIFICATION,
  NEW_PRINTER_ADDED,
  TUTORIAL
} from './utils/constants';
import GenericThemeProvider from './shared/GenericThemeProvider';
import Tutorial from './components/Tutorial';
import NewPrinterAddedModal from './components/NewPrinterAddedModal';
import Footer from './components/Footer';
import EmailNotificationModal from './components/EmailNotificationModal';
import NewPrinterAddedModalV2 from './components/NewPrinterAddedModalV2';

const App = (props) => {
  const {
    properties: { showPostOobeModals, ...footerProperties }
  } = props;
  const { shell } = useRootContext();
  const { navigation } = shell.v1;
  const [showTutorial, setShowTutorial] = useState(TUTORIAL.STATES.CHECK);
  const [showNewPrinterAdded, setShowNewPrinterAdded] = useState(
    NEW_PRINTER_ADDED.STATES.CHECK
  );
  const [printerAddedCookieInfo, setPrinterAddedCookieInfo] = useState({});
  const [showEmailNotificationModal, setShowEmailNotificationModal] = useState(
    EMAIL_NOTIFICATION.STATES.CHECK
  );

  const { deleteCookie, getCookie } = cookies;
  const showHomeModals = useMemo(
    () => showPostOobeModals && navigation.location.pathname === DASHBOARD_PATH,
    [navigation.location.pathname, showPostOobeModals]
  );

  const {
    finishSetupEmailNotificationModal,
    finishSetupNewFooter,
    finishSetupPrinterAddedModal,
    finishSetupNewPrinterAddedChanges
  } = useFlags();

  useEffect(() => {
    if (!finishSetupEmailNotificationModal) {
      setShowEmailNotificationModal(EMAIL_NOTIFICATION.STATES.CLOSED);
      return;
    }

    if (showEmailNotificationModal !== EMAIL_NOTIFICATION.STATES.CHECK) {
      return;
    }

    setShowEmailNotificationModal(EMAIL_NOTIFICATION.STATES.SHOW);
  }, [finishSetupEmailNotificationModal, showEmailNotificationModal]);

  useEffect(() => {
    const showTutorialCookie = getCookie(TUTORIAL.COOKIE);

    if (
      showEmailNotificationModal !== EMAIL_NOTIFICATION.STATES.CLOSED &&
      showEmailNotificationModal !== EMAIL_NOTIFICATION.STATES.ON_BACKGROUND
    ) {
      return;
    }

    if (!showTutorialCookie) {
      setShowTutorial(TUTORIAL.STATES.CLOSED);
      return;
    }

    if (showTutorialCookie.toLowerCase() === 'true') {
      setShowTutorial(TUTORIAL.STATES.SHOW);
    } else {
      setShowTutorial(TUTORIAL.STATES.CLOSED);
    }
  }, [getCookie, showEmailNotificationModal]);

  useEffect(() => {
    const printerAddedCookie = getCookie(NEW_PRINTER_ADDED.COOKIE);

    if (showTutorial !== TUTORIAL.STATES.CLOSED) {
      return;
    }

    if (printerAddedCookie !== null && finishSetupPrinterAddedModal) {
      try {
        const printerAddedCookieParsed = JSON.parse(printerAddedCookie);
        setPrinterAddedCookieInfo(printerAddedCookieParsed);
      } catch {
        setPrinterAddedCookieInfo({ cloudID: printerAddedCookie });
      }
      setShowNewPrinterAdded(NEW_PRINTER_ADDED.STATES.SHOW);
    } else {
      setShowNewPrinterAdded(NEW_PRINTER_ADDED.STATES.CLOSED);
    }
  }, [
    finishSetupPrinterAddedModal,
    getCookie,
    showTutorial,
    showEmailNotificationModal
  ]);

  const getPrinterAddedModal = () => {
    const onFinish = () => {
      deleteCookie(NEW_PRINTER_ADDED.COOKIE);
      setShowNewPrinterAdded(NEW_PRINTER_ADDED.STATES.CLOSED);
    };

    if (finishSetupNewPrinterAddedChanges) {
      return (
        <NewPrinterAddedModalV2
          printerAddedCookieInfo={printerAddedCookieInfo}
          onFinish={onFinish}
        />
      );
    } else {
      return (
        <NewPrinterAddedModal
          printerAddedCookieInfo={printerAddedCookieInfo}
          onFinish={onFinish}
        />
      );
    }
  };

  return (
    <GenericThemeProvider>
      {showNewPrinterAdded === NEW_PRINTER_ADDED.STATES.SHOW &&
      showHomeModals ? (
        getPrinterAddedModal()
      ) : (
        <></>
      )}
      {showPostOobeModals &&
        finishSetupEmailNotificationModal &&
        (showEmailNotificationModal === EMAIL_NOTIFICATION.STATES.SHOW ||
          showEmailNotificationModal ===
            EMAIL_NOTIFICATION.STATES.ON_BACKGROUND) && (
          <EmailNotificationModal
            onClose={() =>
              setShowEmailNotificationModal(EMAIL_NOTIFICATION.STATES.CLOSED)
            }
            onHide={() =>
              setShowEmailNotificationModal(
                EMAIL_NOTIFICATION.STATES.ON_BACKGROUND
              )
            }
          />
        )}
      {showTutorial === TUTORIAL.STATES.SHOW && showHomeModals ? (
        <Tutorial
          onFinish={() => {
            deleteCookie(TUTORIAL.COOKIE);
            setTimeout(() => {
              setShowTutorial(TUTORIAL.STATES.CLOSED);
            }, 200);
          }}
        />
      ) : (
        <></>
      )}
      {finishSetupNewFooter && <Footer {...footerProperties} />}
    </GenericThemeProvider>
  );
};

App.propTypes = {
  properties: PropTypes.shape({
    analyticsActivity: PropTypes.string,
    backgroundColor: PropTypes.string,
    maxWidth: PropTypes.string,
    showPostOobeModals: PropTypes.bool,
    showHpDotComLink: PropTypes.bool,
    showHpSupportLink: PropTypes.bool,
    showHpTermsOfUseLink: PropTypes.bool,
    showHpPrivacyLink: PropTypes.bool,
    showDataRightsNoticeLink: PropTypes.bool
  })
};

App.defaultProps = {
  properties: {
    analyticsActivity: 'Portal-v01',
    backgroundColor: '#FFFFFF',
    maxWidth: '1660px',
    showPostOobeModals: false,
    showHpDotComLink: false,
    showHpSupportLink: false,
    showHpTermsOfUseLink: false,
    showHpPrivacyLink: false,
    showDataRightsNoticeLink: false
  }
};

export default App;
