import PropTypes from 'prop-types';
import {
  DASHBOARD_PATH,
  HELP_CENTER_PATH,
  PRINTERS_PATH,
  SETTINGS_PATH,
  SOLUTIONS_PATH,
  USERS_PATH
} from './constants';

const activity = 'Home-v01';
const screenPath = '/Home/';
const footerScreenPath = '/';

const eventDetailVersion = '1.3.0';

const pathnameToActivity = (pathname) => {
  switch (pathname) {
    case DASHBOARD_PATH:
      return 'Home-v01';
    case PRINTERS_PATH:
      return 'Printers-v01';
    case USERS_PATH:
      return 'Users-v01';
    case SOLUTIONS_PATH:
      return 'Solutions-v01';
    case SETTINGS_PATH:
      return 'Settings';
    case HELP_CENTER_PATH:
      return 'HelpCenter-v01';
    default:
      return undefined;
  }
};

const pathnameToScreenPath = (pathname) => {
  switch (pathname) {
    case DASHBOARD_PATH:
      return '/Home/';
    case PRINTERS_PATH:
      return '/ReactSmbPrinters/';
    case USERS_PATH:
      return '/ReactUserManagement/';
    case SOLUTIONS_PATH:
      return '/ReactSmbSolutions/';
    case SETTINGS_PATH:
      return '/ReactSmbSettings/';
    case HELP_CENTER_PATH:
      return '/ReactSmbHelpCenter/';
    default:
      return undefined;
  }
};

export const publishEvent = (event, options?) => {
  const { publishCdmEvents } = window.Shell.v1.analytics;

  if (!publishCdmEvents) {
    return;
  }

  publishCdmEvents(
    [
      {
        dateTime: new Date().toISOString(),
        eventDetailType:
          'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
        eventCategory: 'simpleUi',
        eventDetail: event,
        version: '1.4.0'
      }
    ],
    options
  );
};

export const AnalyticsEventPropTypes = {
  action: PropTypes.string.isRequired,
  activity: PropTypes.string.isRequired,
  screenPath: PropTypes.string.isRequired,
  screenName: PropTypes.string.isRequired,
  screenMode: PropTypes.string.isRequired,
  controlName: PropTypes.string.isRequired,
  controlDetail: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired
};

const ACTIONS = {
  MODULE_DISPLAYED: 'ModuleDisplayed',
  SCREEN_DISPLAYED: 'ScreenDisplayed',
  MODAL_WINDOW_DISPLAYED: 'ModalWindowDisplayed',
  CONTROL_BUTTON_CLICKED: 'ControlButtonClicked',
  CONTROL_HYPERLINK_CLICKED: 'ControlHyperLinkClicked'
};

// WELCOME TUTORIAL

export const WelcomeTutorialModalDisplayed = {
  action: ACTIONS.MODAL_WINDOW_DISPLAYED,
  activity,
  screenPath,
  screenName: 'WelcomeTutorial',
  version: eventDetailVersion
};

export const WelcomeTutorialButtonNext = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'WelcomeTutorial',
  controlName: 'NextButton',
  version: eventDetailVersion
};

export const WelcomeTutorialButtonDismiss = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'WelcomeTutorial',
  controlName: 'DismissButton',
  version: eventDetailVersion
};

export const WelcomeTutorialButtonSkip = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'WelcomeTutorial',
  controlName: 'SkipButton',
  version: eventDetailVersion
};

// NOTIFICATIONS TUTORIAL

export const NotificationTutorialModalDisplayed = {
  action: ACTIONS.MODAL_WINDOW_DISPLAYED,
  activity,
  screenPath,
  screenName: 'NotificationTutorial',
  version: eventDetailVersion
};

export const NotificationTutorialButtonNext = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'NotificationTutorial',
  controlName: 'NextButton',
  version: eventDetailVersion
};

export const NotificationTutorialButtonDismiss = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'NotificationTutorial',
  controlName: 'DismissButton',
  version: eventDetailVersion
};

export const NotificationTutorialButtonBack = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'NotificationTutorial',
  controlName: 'BackButton',
  version: eventDetailVersion
};

// MANAGE PRINTER TUTORIAL

export const ManagePrinterTutorialModalDisplayed = {
  action: ACTIONS.MODAL_WINDOW_DISPLAYED,
  activity,
  screenPath,
  screenName: 'ManagePrinterTutorial',
  version: eventDetailVersion
};

export const ManagePrinterTutorialButtonNext = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'ManagePrinterTutorial',
  controlName: 'NextButton',
  version: eventDetailVersion
};

export const ManagePrinterTutorialButtonDismiss = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'ManagePrinterTutorial',
  controlName: 'DismissButton',
  version: eventDetailVersion
};

export const ManagePrinterTutorialButtonBack = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'ManagePrinterTutorial',
  controlName: 'BackButton',
  version: eventDetailVersion
};

// MANAGE USERS TUTORIAL

export const ManageUsersTutorialModalDisplayed = {
  action: ACTIONS.MODAL_WINDOW_DISPLAYED,
  activity,
  screenPath,
  screenName: 'ManageUsersTutorial',
  version: eventDetailVersion
};

export const ManageUsersTutorialButtonGetStarted = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'ManageUsersTutorial',
  controlName: 'GetStartButton',
  version: eventDetailVersion
};

export const ManageUsersTutorialButtonDismiss = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'ManageUsersTutorial',
  controlName: 'DismissButton',
  version: eventDetailVersion
};

export const ManageUsersTutorialButtonBack = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'ManageUsersTutorial',
  controlName: 'BackButton',
  version: eventDetailVersion
};

// NEW PRINTER ADDED

export const NewPrinterAddedModalDisplayed = {
  action: ACTIONS.MODAL_WINDOW_DISPLAYED,
  activity,
  screenPath,
  screenName: 'NewPrinterAddedModal',
  version: eventDetailVersion
};

export const NewPrinterAddedButtonDashboard = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'NewPrinterAddedModal',
  controlName: 'GoToDashboardButton',
  version: eventDetailVersion
};

export const NewPrinterAddedButtonStart = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'NewPrinterAddedModal',
  controlName: 'GetStartedButton',
  version: eventDetailVersion
};

export const NewPrinterAddedButtonDismiss = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'NewPrinterAddedModal',
  controlName: 'DismissButton',
  version: eventDetailVersion
};

// FOOTER

export const footerHpDotComLink = (analyticsActivity) => ({
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity: analyticsActivity,
  screenPath: footerScreenPath,
  screenName: 'FooterSection',
  controlName: 'HpDotComLink',
  version: eventDetailVersion
});

export const footerHpSupportLink = (analyticsActivity) => ({
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity: analyticsActivity,
  screenPath: footerScreenPath,
  screenName: 'FooterSection',
  controlName: 'HpSupportLink',
  version: eventDetailVersion
});

export const footerTermsOfUseLink = (analyticsActivity) => ({
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity: analyticsActivity,
  screenPath: footerScreenPath,
  screenName: 'FooterSection',
  controlName: 'TermsOfUseLink',
  version: eventDetailVersion
});

export const footerHpPrivacyLink = (analyticsActivity) => ({
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity: analyticsActivity,
  screenPath: footerScreenPath,
  screenName: 'FooterSection',
  controlName: 'HpPrivacyLink',
  version: eventDetailVersion
});

export const footerDataRightsNoticeLink = (analyticsActivity) => ({
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity: analyticsActivity,
  screenPath: footerScreenPath,
  screenName: 'FooterSection',
  controlName: 'DataRightsNoticeLink',
  version: eventDetailVersion
});

export const notificationsModalWindowDisplayed = {
  action: ACTIONS.MODAL_WINDOW_DISPLAYED,
  activity: pathnameToActivity(activity),
  screenPath: pathnameToScreenPath(screenPath),
  screenName: 'EmailNotificationsModal',
  version: eventDetailVersion
};

export const notificationsModalButtonTurnOn = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity: pathnameToActivity(activity),
  screenPath: pathnameToScreenPath(screenPath),
  screenName: 'EmailNotificationsModal',
  controlName: 'TurnOnButton',
  version: eventDetailVersion
};

export const notificationsModalButtonNotNow = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity: pathnameToActivity(activity),
  screenPath: pathnameToScreenPath(screenPath),
  screenName: 'EmailNotificationsModal',
  controlName: 'NotNowButton',
  version: eventDetailVersion
};

export { pathnameToActivity, pathnameToScreenPath };
