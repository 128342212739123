import styled from 'styled-components';
import { mixins } from '@jarvis/react-portal-addons';
import { Modal as VeneerModal } from '@veneer/core';
import primitives from '@veneer/primitives';

export const Modal = styled(VeneerModal)`
  .vn-modal--content {
    border: 0;
    padding: 64px 48px;

    ${mixins.sm(`
      width: 515px;
    `)}
  }

  .vn-modal--body {
    justify-content: center;
  }

  .vn-modal--footer {
    display: flex;
    justify-content: center;
    padding: 0;
  }
`;

export const Title = styled.div`
  margin-bottom: ${primitives.layout.size4}px;

  ${mixins.md(`
    margin-bottom: ${primitives.layout.size5}px;
  `)}
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${primitives.layout.size5}px;
  align-items: start;

  ${mixins.sm(`
    align-items: center;
  `)}
`;

export const PrinterInfoContainer = styled.div`
  align-items: center;
  display: none;
  height: 128px;
  margin-bottom: ${primitives.layout.size5}px;

  ${mixins.sm(`
    display: flex;
    width: 100%;
  `)}
`;

export const PrinterImageContainer = styled.div`
  position: relative;
  max-width: 100%;

  > img {
    width: 128px;
    height: auto;
  }
`;

export const Logo = styled.div`
  width: 48px;
  height: 48px;
  left: 0;
  bottom: 0;
  position: absolute;
`;

export const PrinterNameLocation = styled.div`
  display: flex;
  overflow: hidden;
  max-width: 100%;
  flex-direction: column;
  justify-content: center;
  margin-left: ${primitives.layout.size6}px;

  .body {
    color: ${primitives.color.gray9};
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;

  button:first-child {
    margin-top: ${primitives.layout.size3}px;
  }

  ${mixins.sm(`
    flex-direction: row;
    margin: 0 ${primitives.layout.size3}px;
    justify-content: space-between;

    button:first-child {
      width: 245px;
      margin-top: 0;
    }
  `)}
`;

export const PrinterFriendlyName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;
